exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-infofirme-privacy-policy-js": () => import("./../../../src/pages/infofirme/privacy-policy.js" /* webpackChunkName: "component---src-pages-infofirme-privacy-policy-js" */),
  "component---src-pages-infofirme-terms-and-conditions-js": () => import("./../../../src/pages/infofirme/terms-and-conditions.js" /* webpackChunkName: "component---src-pages-infofirme-terms-and-conditions-js" */),
  "component---src-pages-magicfm-privacy-policy-js": () => import("./../../../src/pages/magicfm/privacy-policy.js" /* webpackChunkName: "component---src-pages-magicfm-privacy-policy-js" */),
  "component---src-pages-tvr-privacy-policy-js": () => import("./../../../src/pages/tvr/privacy-policy.js" /* webpackChunkName: "component---src-pages-tvr-privacy-policy-js" */),
  "component---src-pages-tvr-terms-and-conditions-js": () => import("./../../../src/pages/tvr/terms-and-conditions.js" /* webpackChunkName: "component---src-pages-tvr-terms-and-conditions-js" */)
}

