module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["G-8F5MHG8QNB"],"gtagConfig":{"anonymize_ip":true},"pluginConfig":{"head":false,"respectDNT":true}},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Molcode","short_name":"Molcode","start_url":"/","background_color":"#fff","theme_color":"#111","display":"standalone","icon":"src/images/icon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"cbcb72b68d537ebdd6c9200b8e6c3513"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
